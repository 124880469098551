import React, { Component, useEffect, useState, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import { Auth } from "aws-amplify";

import { useCookies } from "react-cookie";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";

import { LoginContext } from "../LoginContext";
import {
  getUserAuth,
  removeUserCookies,
} from "../infrastructure/UserAuthentication";
import ProtectPage from "../pages/protect-page/ProtectPage";
import "./AppRouting.scss";
import { FaWhatsapp } from "react-icons/fa";

const HomePage = lazy(() => import("../pages/homepage/HomePage"));
const GMCCoursePage = lazy(() => import("../pages/gmc-course/GMCCoursePage"));
const ModuleSummaryPage = lazy(() =>
  import("../pages/course-modules/module-summary/ModuleSummaryPage")
);
const CourseClosingSummaryPage = lazy(() =>
  import("../pages/course/course-closing-summary/CourseClosingSummaryPage")
);
const CourseWelcomePage = lazy(() =>
  import("../pages/course/course-welcome/CourseWelcomePage")
);
const CourseMasterPage = lazy(() =>
  import("../pages/course/course-master/CourseMaster")
);
const CourseEvaluationPage = lazy(() =>
  import("../pages/course/course-evaluation/CourseEvaluationPage")
);
const CourseLandingPage = lazy(() =>
  import("../pages/course/course-landing/CourseLandingPage")
);
const CourseClosingPage = lazy(() =>
  import("../pages/course/course-closing/CourseClosingPage")
);
const DailyPaymentTransaction = lazy(() =>
  import("../pages/admin/daily-payment-transaction/DailyPaymentTransactionPage")
);
const LoginAdminPage = lazy(() =>
  import("../pages/authentication/login/LoginAdminPage")
);
const OtpLoginPage = lazy(() =>
  import("../pages/authentication/login/LoginOTPPage")
);
const FAQPage = lazy(() => import("../pages/faq/FAQPage"));
const RegistrationFormPage = lazy(() =>
  import("../pages/authentication/registration-form/RegistrationFormPage")
);
const LoginPage = lazy(() => import("../pages/authentication/login/LoginPage"));
const SingPassPage = lazy(() =>
  import("../pages/authentication/singpass/singpass-login/SingPassPage")
);
const SingPassCallBackPage = lazy(() =>
  import(
    "../pages/authentication/singpass/singpass-callback/SingPassCallBackPage"
  )
);
const RegistrationAgreementPage = lazy(() =>
  import(
    "../pages/authentication/registration-agreement/RegistrationAgreementPage"
  )
);
const CoursePage = lazy(() =>
  import("../pages/course/course-progress/ProgressPage")
);
const CourseVideoPage = lazy(() =>
  import("../pages/course-video/CourseVideoPage")
);
const CustomerListPage = lazy(() =>
  import("../pages/admin/customer-list/CustomerListPage")
);
const UserListPage = lazy(() =>
  import("../pages/admin/user-list/UserListPage")
);
const MOMReportPage = lazy(() =>
  import("../pages/admin/mom-report/MOMReportPage")
);
const CustomerDetailsPage = lazy(() =>
  import("../pages/admin/customer-details/CustomerDetailsPage")
);
const CreditCardPaymentConfirmationPage = lazy(() =>
  import(
    "../pages/payment/payment-page-confirmation/CreditCardPaymentConfirmationPage"
  )
);
const CreditCardPaymentPage = lazy(() =>
  import("../pages/payment/payment-page/CreditCardPaymentPage")
);
const CreditCardPaymentCallBackPage = lazy(() =>
  import("../pages/payment/payment-callback/CreditCardPaymentCallBackPage")
);
const AnnouncementSummaryPage = lazy(() =>
  import("../pages/admin/announcement/AnnouncementSummaryPage")
);
const AnnouncementFormPage = lazy(() =>
  import("../components/admin/announcement-form/AnnouncementForm")
);
const TermsAndConditionPage = lazy(() =>
  import("../pages/terms-and-condition/TermsAndConditionPage")
);
const ErrorPage = lazy(() => import("../pages/error/ErrorPage"));
const EvaluationAnswerSummaryPage = lazy(() =>
  import("../pages/admin/evaluation-summary/EvaluationAnswerSummaryPage")
);
const SettingsPage = lazy(() => import("../pages/admin/settings/SettingsPage"));

function AuthenticatedRoute(myProps) {
  return (
    <React.Fragment>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route
            path="/course/summary"
            render={(props) => <ModuleSummaryPage {...props} {...myProps} />}
          />
          <Route
            path="/course-evaluation"
            render={(props) => <CourseEvaluationPage {...props} {...myProps} />}
          />
          <Route
            path="/course-landing"
            render={(props) => <CourseLandingPage {...props} {...myProps} />}
          />
          <Route
            path="/course-closing"
            render={(props) => <CourseClosingPage {...props} {...myProps} />}
          />
          <Route
            path="/course-closing-summary"
            render={(props) => (
              <CourseClosingSummaryPage {...props} {...myProps} />
            )}
          />
          <Route
            path="/course/:slug"
            render={(props) => <CourseMasterPage {...props} {...myProps} />}
          />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
}

function AuthRoute(props) {
  const [cookies, setCookie, removeCookie] = useCookies(["eop"]);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  let userid = cookies.user_token;
  useEffect(() => {
    async function getUserSession() {
      let currentUserAuth = await getUserAuth();

      if (!currentUserAuth || !currentUserAuth.userIsAuthorized) {
        props.history.push("/login");
      } else {
        setIsAuthenticated(currentUserAuth.userIsAuthorized);
      }
    }
    getUserSession();
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      {isAuthenticated ? (
        <AuthenticatedRoute {...props} userid={userid} />
      ) : null}
    </React.Fragment>
  );
}

function AdminRoute(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  // const [isAdmin, setIsAdmin] = useState(false)
  const [adminGroup, setAdminGroup] = useState(null);
  function checkAccess() {
    return new Promise((resolve, reject) => {
      try {
        Auth.currentSession();
        //this.setAuthStatus(true);
        Auth.currentAuthenticatedUser().then(
          (response) => {
            //resolve(response.signInUserSession.accessToken.payload['cognito:groups'].includes('Admin'))
            // setIsAdmin(response.signInUserSession.accessToken.payload['cognito:groups'].includes('Admin'));
            setAdminGroup(
              response.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes("Admin")
                ? "Admin"
                : response.signInUserSession.accessToken.payload[
                    "cognito:groups"
                  ].includes("User")
                ? "User"
                : null
            );
            setIsAuthenticating(false);
          },
          (error) => {
            // setIsAdmin(false);
            setIsAuthenticating(false);
          }
        );
        //this.setUser(user);
      } catch (error) {
        // setIsAdmin(false);
        setIsAuthenticating(false);
        reject("No access available");
      }
    });
  }

  useEffect(() => {
    checkAccess();
  }, []);
  return (
    <React.Fragment>
      {isAuthenticating ? null : adminGroup === "Admin" ? (
        <React.Fragment>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route
                path="/admin/userlist"
                render={(props) => (
                  <UserListPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                path="/admin/userdetails/view/:userid"
                render={(props) => (
                  <CustomerDetailsPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                path="/admin/userdetails/edit/:userid"
                render={(props) => (
                  <CustomerDetailsPage
                    edit
                    adminGroup={adminGroup}
                    {...props}
                  />
                )}
              />
              <Route
                path="/admin/momreport"
                render={(props) => (
                  <MOMReportPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                path="/admin/announcement/add"
                render={(props) => (
                  <AnnouncementFormPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                path="/admin/announcement/edit/:announcementid"
                render={(props) => (
                  <AnnouncementFormPage
                    edit
                    adminGroup={adminGroup}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/admin/announcement"
                render={(props) => (
                  <AnnouncementSummaryPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                exact
                path="/admin/daily-payment-transaction"
                render={(props) => (
                  <DailyPaymentTransaction adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                exact
                path="/admin/evaluation-summary"
                render={(props) => (
                  <EvaluationAnswerSummaryPage
                    adminGroup={adminGroup}
                    {...props}
                  />
                )}
              />
              <Route
                path="/admin/settings"
                render={(props) => (
                  <SettingsPage adminGroup={adminGroup} {...props} />
                )}
              />
            </Switch>
          </Suspense>
        </React.Fragment>
      ) : adminGroup === "User" ? (
        <React.Fragment>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route
                path="/admin/userlist"
                render={(props) => (
                  <UserListPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                path="/admin/userdetails/view/:userid"
                render={(props) => (
                  <CustomerDetailsPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                path="/admin/userdetails/edit/:userid"
                render={(props) => {
                  props.history.push("/error");
                }}
              />
              <Route
                path="/admin/momreport"
                render={(props) => (
                  <MOMReportPage adminGroup={adminGroup} {...props} />
                )}
              />
              <Route
                path="/admin/announcement/add"
                render={(props) => {
                  props.history.push("/error");
                }}
              />
              <Route
                path="/admin/announcement/edit/:announcementid"
                render={(props) => {
                  props.history.push("/error");
                }}
              />
              <Route
                exact
                path="/admin/announcement"
                render={(props) => (
                  <AnnouncementSummaryPage adminGroup={adminGroup} {...props} />
                )}
              />
            </Switch>
          </Suspense>
        </React.Fragment>
      ) : (
        props.history.push("/admin/login")
      )}
    </React.Fragment>
  );
}

const WhatsAppButton = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="whatsapp-button-container">
      <span className={`whatsapp-text ${isHovered ? "hovered" : ""}`}>
        Chat with us
      </span>
      <div
        className="whatsapp-button"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FaWhatsapp
          size={32}
          color="white"
          className="whatsapp-icon"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default class AppRouting extends Component {
  handleWhatsAppRedirect = () => {
    window.open("https://wa.me/+6580511051", "_blank");
  };

  render() {
    const isAdminPath = window.location.pathname.includes("admin");

    return (
      <React.Fragment>
        <Suspense fallback={<div></div>}>
          {/* TO DO: Refine Routing Page and Base Page for routing */}
          <Switch>
            {/* auth route */}
            <Route exact path="/" component={HomePage} />
            <Route exact path="/homepage" component={HomePage} />
            <Route path="/gmc-course/:slug" component={GMCCoursePage} />
            <Route path="/course/welcome" component={CourseWelcomePage} />
            <Route path="/course/summary" component={AuthRoute} />
            <Route path="/course-closing-summary" component={AuthRoute} />
            <Route path="/course-evaluation" component={AuthRoute} />
            <Route path="/course-landing" component={AuthRoute} />
            <Route path="/course-closing" component={AuthRoute} />
            <Route path="/course/:slug" component={AuthRoute} />
            <Route path="/course/welcome" component={CourseWelcomePage} />
            <Route
              path="/login"
              render={(props) => (
                <OtpLoginPage
                  {...props}
                  isRefreshed={this.props.isRefreshed}
                ></OtpLoginPage>
              )}
            />
            <Route
              exact
              path="/registration/agreement"
              component={RegistrationAgreementPage}
            />
            <Route
              exact
              path="/registration/form"
              render={(props) => (
                <RegistrationFormPage {...props}></RegistrationFormPage>
              )}
            />
            <Route path="/payment" component={CreditCardPaymentPage} />
            <Route
              path="/payment-confirmation"
              component={CreditCardPaymentConfirmationPage}
            />
            <Route
              path="/payment-callback"
              component={CreditCardPaymentCallBackPage}
            />
            <Route path="/faq" component={FAQPage} />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditionPage}
            />
            <Route path="/singpass" component={SingPassPage} />
            <Route path="/spcallback" component={SingPassCallBackPage} />
            {/* <Route path="/login" component={LoginPage}/> */}
            <Route
              path="/admin/login"
              render={(props) => (
                <LoginAdminPage
                  isRefreshed={this.props.isRefreshed}
                  checkAccess={this.checkAccess}
                  {...props}
                ></LoginAdminPage>
              )}
            />

            <Route path="/admin/userlist" component={AdminRoute} />
            <Route
              path="/admin/userdetails/view/:userid"
              component={AdminRoute}
            />
            <Route
              path="/admin/userdetails/edit/:userid"
              component={AdminRoute}
            />
            <Route path="/admin/momreport" component={AdminRoute} />
            <Route path="/admin/announcement/add" component={AdminRoute} />
            <Route
              path="/admin/announcement/edit/:announcementid"
              component={AdminRoute}
            />
            <Route exact path="/admin/announcement" component={AdminRoute} />
            <Route
              exact
              path="/admin/daily-payment-transaction"
              component={AdminRoute}
            />
            <Route
              exact
              path="/admin/evaluation-summary"
              component={AdminRoute}
            />
            <Route path="/admin/settings" component={AdminRoute} />
            {/* <Route path={'/course-completion/:guid'} component={CourseCompletionPage}/> */}

            <Route path="/admin/customerlist" component={CustomerListPage} />
            <Route path="/progress" component={CoursePage} />
            <Route
              exact
              path={"/logout"}
              render={(someProps) => {
                this.props.triggerLogout(someProps);
              }}
            />
            <Route path={"/video/:slug"} component={CourseVideoPage} />
            <Route path={"/error"} component={ErrorPage} />
            <Route path="*" component={ErrorPage} />
          </Switch>

          {!isAdminPath && (
            <WhatsAppButton onClick={this.handleWhatsAppRedirect} />
          )}
        </Suspense>
      </React.Fragment>
    );
  }
}
